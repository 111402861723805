<template>
  <div>
    <page-header class="mb-3" :title="$t('Privacy policy')" />
    <b-row class="d-flex justify-content-center">
      <b-col xs="12" md="8">
        <b-card>
          <b-card-text>
            <p><b>1.</b> {{ $t('Pricacy policy - 1') }}</p>

            <p><b>2.</b> {{ $t('Pricacy policy - 2') }}</p>

            <p><b>3.</b> {{ $t('Pricacy policy - 3') }}</p>

            <p><b>4.</b> {{ $t('Pricacy policy - 4') }}</p>

            <p><b>5.</b> {{ $t('Pricacy policy - 5') }}</p>

            <p><b>6.</b> {{ $t('Pricacy policy - 6') }}</p>

            <p><b>7.</b> {{ $t('Pricacy policy - 7') }}</p>

            <p><b>8.</b> {{ $t('Pricacy policy - 8') }}</p>
            <p>{{ $t('Pricacy policy - 8a') }}</p>
            <p>{{ $t('Pricacy policy - 8b') }}</p>
            <p>{{ $t('Pricacy policy - 8c') }}</p>
            <p>{{ $t('Pricacy policy - 8d') }}</p>

            <p><b>9.</b> {{ $t('Pricacy policy - 9') }}</p>

            <p><b>10.</b> {{ $t('Pricacy policy - 10 - p1') }}</p>
            <p>{{ $t('Pricacy policy - 10 - p2') }}</p>
            <p>{{ $t('Pricacy policy - 10 - p3') }}</p>
            <ul>
              <li>{{ $t('Pricacy policy - 10 - b1') }}</li>
              <li>{{ $t('Pricacy policy - 10 - b2') }}</li>
              <li>{{ $t('Pricacy policy - 10 - b3') }}</li>
              <li>{{ $t('Pricacy policy - 10 - b4') }}</li>
            </ul>

            <p><b>11.</b> {{ $t('Pricacy policy - 11') }}</p>

            <p><b>12.</b> {{ $t('Pricacy policy - 12') }}</p>

            <p><b>13.</b> {{ $t('Pricacy policy - 13') }}</p>

            <p><b>14.</b> {{ $t('Pricacy policy - 14') }}</p>
            <p>{{ $t('Pricacy policy - 14a - p1') }}</p>
            <p>{{ $t('Pricacy policy - 14a - p2') }}</p>
            <p>{{ $t('Pricacy policy - 14b - p1') }}</p>
            <p>{{ $t('Pricacy policy - 14b - p2') }}</p>
            <p>{{ $t('Pricacy policy - 14c - p1') }}</p>
            <p>{{ $t('Pricacy policy - 14c - p2') }}</p>
            <p>{{ $t('Pricacy policy - 14d - p1') }}</p>
            <p>{{ $t('Pricacy policy - 14d - p2') }}</p>
            <p>{{ $t('Pricacy policy - 14e - p1') }}</p>
            <p>{{ $t('Pricacy policy - 14e - p2') }}</p>
            <p>{{ $t('Pricacy policy - 14f - p1') }}</p>
            <p>{{ $t('Pricacy policy - 14f - p2') }}</p>

            <p><b>15.</b> {{ $t('Pricacy policy - 15') }}</p>

            <p><b>16.</b> {{ $t('Pricacy policy - 16') }}</p>

            <p><b>17.</b> {{ $t('Pricacy policy - 17') }}</p>

            <p><b>18.</b> {{ $t('Pricacy policy - 18') }}</p>

            <p><b>19.</b> {{ $t('Pricacy policy - 19 - p1') }}</p>
            <p>{{ $t('Pricacy policy - 19 - p2') }}</p>
          </b-card-text>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BCard,
  BCardText,
  BCol,
  BRow,
} from 'bootstrap-vue'
import PageHeader from '@mentoring-platform/views/components/blocks/PageHeader.vue'

export default {
  components: {
    BCard,
    BCardText,
    BCol,
    BRow,
    PageHeader,
  },
  data() {
    return {

    }
  },
}
</script>

<style scoped>
p {
  line-height: 1.8rem;
}
</style>
